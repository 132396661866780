import Head from 'next/head';
import Landing from '../src/legacy/components/pages/Landing/Landing';

export const Home = (): JSX.Element => (
  <>
    <Head>
      <title>Bounce</title>
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://bouncelife.com" />
      <meta property="og:title" content="Bounce" />
      <meta property="fb:app_id" content="1855802768063770" />
      <meta
        property="og:image"
        content="/img/illustrations/landing/about-image.jpg"
      />
      <meta
        property="og:description"
        content="Bounced Inc. is a fin-tech startup that is redefining in-person events. Our platform is centred around event ticketing, increasing guest engagement, and creating communities on campus. We are the one-stop-shop for all your social event needs, and we can even handle custom apparel and drink sponsorships through our partners."
      />
    </Head>

    <Landing />
  </>
);

export async function getStaticProps() {
  return {
    props: {} // will be passed to the page component as props
  };
}

export default Home;
