import { useEffect, useRef } from 'react';

type VideoProps = {
  sources: {
    src: string;
    type: string;
  }[];
};

const Video: React.FC<VideoProps> = ({ sources }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    videoRef.current.defaultMuted = true;
    videoRef.current.muted = true;
  }, []);

  return (
    <video ref={videoRef} muted loop playsInline autoPlay>
      {sources.map((source) => (
        <source key={source.src} src={source.src} type={source.type} />
      ))}
    </video>
  );
};

export default Video;
