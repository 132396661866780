import styles from './Landing.module.scss';

import Stats from './Stats';
import Banner from './Banner';
import Features from './Features';
import TrustedBy from './TrustedBy';
import HostEvent from './HostEvent';

function Landing() {
  return (
    <div className={styles.Landing}>
      <Banner />
      <Features />
      <Stats />
      <TrustedBy />
      <HostEvent />
    </div>
  );
}

export default Landing;
