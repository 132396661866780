import cn from 'classnames';
import styles from './TrustedBy.module.scss';

const TrustedBy = () => (
  <div>
    <div className={cn(styles.Sponsors, styles.Container)}>
      <h2>Trusted By</h2>
      <div className={styles.SponsorsList}>
        <a
          href="https://www.aepi.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/img/sponsors/aepi.png" alt="" />
        </a>
        <a href="https://myams.org/" target="_blank" rel="noopener noreferrer">
          <img src="/img/sponsors/ams.png" alt="" />
        </a>
        <a
          href="https://www.cs.queensu.ca/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/img/sponsors/computing.png" alt="" />
        </a>
        <a
          href="http://generocksity.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/img/sponsors/generoksity.jpg" alt="" />
        </a>
        <a href="https://qflip.ca/" target="_blank" rel="noopener noreferrer">
          <img src="/img/sponsors/qflip.png" alt="" />
        </a>
        <a href="https://qmind.ca/" target="_blank" rel="noopener noreferrer">
          <img src="/img/sponsors/qmind.png" alt="" />
        </a>
        <a href="https://cucai.ca/" target="_blank" rel="noopener noreferrer">
          <img src="/img/sponsors/cucai.jpg" alt="" />
        </a>
        <a
          href="https://zetapsi.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/img/sponsors/zetapsi.png" alt="" />
        </a>
        <a
          href="https://www.queensstudentdiversityproject.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/img/sponsors/qsdp.jpg" alt="" />
        </a>
        <a
          href="https://engsoc.queensu.ca/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/img/sponsors/engsoc.jpg" alt="" />
        </a>
        <a
          href="https://www.voguecharityfashionshow.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/img/sponsors/vcfs.jpg" alt="" />
        </a>
        <a
          href="https://calumet.yorku.ca/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/img/sponsors/calumet.jpg" alt="" />
        </a>
      </div>
    </div>
  </div>
);

export default TrustedBy;
