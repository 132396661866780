import React from 'react';
import Link from 'next/link';
import styles from './ButtonPill.module.scss';

const ButtonPill = (props) => (
  <div style={{ paddingTop: [props.pt], paddingBottom: [props.pb] }}>
    <Link href={props.link} legacyBehavior>
      <div className={styles.PillLink}>
        <button className={styles[props.type]} type="button">
          {props.children}
        </button>
      </div>
    </Link>
  </div>
);

export default ButtonPill;
