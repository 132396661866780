import styles from './HostEvent.module.scss';

import ButtonPill from '../../util/ButtonPill/ButtonPill';

const HostEvent = () => (
  <div className={styles.HostEvent}>
    <h2>Host an Event with Bounce today.</h2>
    <ButtonPill type="ButtonWhiteWide" link="/dashboard">
      Get Started
    </ButtonPill>
  </div>
);

export default HostEvent;
