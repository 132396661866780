import cn from 'classnames';
import styles from './Features.module.scss';

import Video from '../../util/Video/Video';
import ButtonPill from '../../util/ButtonPill/ButtonPill';

const Features = () => (
  <div className={styles.Features}>
    <div className={styles.Container}>
      <div className={styles.SectionHeadline}>
        <div className={styles.Description}>
          <h3>Host or attend in-person events.</h3>
          <p className={styles.my2}>
            Explore new interests and in-person events.
          </p>
          <div className={styles.button}>
            <ButtonPill type="ButtonBlueWide" link="/features">
              Learn More
            </ButtonPill>
          </div>
          <div className={styles.FeatureVid}>
            <Video
              sources={[
                {
                  src: '/vid/feature-1.webm',
                  type: 'video/webm; codecs=vp9,vorbis'
                },
                {
                  src: '/vid/feature-1.mp4',
                  type: 'video/mp4'
                }
              ]}
            />
          </div>
        </div>
      </div>
      <div className={styles.SectionHeadline}>
        <div className={cn(styles.Description, styles.Reverse)}>
          <h3>Send and receive money in an instant.</h3>
          <p className={styles.my2}>
            Easily transfer money between your friends with our peer-to-peer
            payment system.
          </p>
          <div className={styles.button}>
            <ButtonPill type="ButtonBlueWide" link="/features">
              Learn More
            </ButtonPill>
          </div>
          <div className={cn(styles.FeatureVid, styles.vid)}>
            <Video
              sources={[
                {
                  src: '/vid/feature-2.webm',
                  type: 'video/webm; codecs=vp9,vorbis'
                },
                {
                  src: '/vid/feature-2.mp4',
                  type: 'video/mp4'
                }
              ]}
            />
          </div>
        </div>
      </div>
      <div className={styles.SectionHeadline}>
        <div className={styles.Description}>
          <h3>Create relationships before, during, and after an event.</h3>
          <p className={styles.my2}>
            Our events platform focuses on the end-to-end event experience, with
            a large focus on building relationships from the pre to post-event
            stage.
          </p>
          <div className={styles.button}>
            <ButtonPill type="ButtonBlueWide" link="/features">
              Learn More
            </ButtonPill>
          </div>
          <div className={cn(styles.FeatureVid, styles.WideScreen)}>
            <Video
              sources={[
                {
                  src: '/vid/feature-3.webm',
                  type: 'video/webm; codecs=vp9,vorbis'
                },
                {
                  src: '/vid/feature-3.mp4',
                  type: 'video/mp4'
                }
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Features;
