import styles from './Banner.module.scss';

import cn from 'classnames';

const Banner = () => (
  <>
    <div className={cn(styles.Background, styles.Shadow)}>
      <div className={styles.BallContainer}>
        <img className={styles.FullBall} src="/img/icons/ball_shadow.svg" />
      </div>
    </div>
    <div className={styles.Background}>
      <div className={styles.BallContainer}>
        <img className={styles.FullBall} src="/img/icons/ball.svg" />
      </div>
    </div>

    <div className={styles.Container}>
      <div className={styles.HeaderBanner}>
        <div className={cn(styles.HeaderText, styles.my4)}>
          <h1>Collect Money</h1>
          <h1>Make Memories</h1>
          <h3>The one-stop shop to easily host and attend events.</h3>
          <div className={styles.buttonContainer}>
            <a href="https://bouncelife.com/ios">
              <img
                className={cn(styles.storeButton, styles.appStore)}
                src="/img/illustrations/landing/app-store.svg"
              />
            </a>
            <a href="https://bouncelife.com/android">
              <img
                className={styles.storeButton}
                src="/img/illustrations/landing/play-store.svg"
              />
            </a>
          </div>
        </div>
        <div className={cn(styles.iPhoneMockup, styles.my5)}>
          <img
            className={styles.iPhone}
            src="/img/illustrations/landing/iphoneMockup.png"
            alt="Bounce App"
          />
          <div className={styles.Bubbles}>
            <img
              className={styles.Bubble1}
              src="/img/illustrations/landing/Randy.png"
              alt="Bounce"
            />
            <img
              className={styles.Bubble2}
              src="/img/illustrations/landing/Kyara.png"
              alt="Bounce"
            />
            <img
              className={styles.Bubble3}
              src="/img/illustrations/landing/Will.png"
              alt="Bounce"
            />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Banner;
