import cn from 'classnames';
import styles from './Stats.module.scss';

const Stats = () => (
  <div className={cn(styles.Container, styles.StatsSection)}>
    <div className={styles.Stat}>
      <img src="/img/illustrations/landing/icon-1.svg" alt="Tickets" />
      <h3>$1/2 million+</h3>
      <div className={styles.Title}>
        Transfers <br />
        Processed
      </div>
    </div>
    <div className={styles.Stat}>
      <img src="/img/illustrations/landing/icon-2.svg" alt="Tickets" />
      <h3>50,000+</h3>
      <div className={styles.Title}>
        Tickets <br />
        Sold
      </div>
    </div>
    <div className={styles.Stat}>
      <img src="/img/illustrations/landing/icon-3.svg" alt="Tickets" />
      <h3>1200+</h3>
      <div className={styles.Title}>
        Events <br /> Hosted
      </div>
    </div>
    <div className={styles.Stat}>
      <img src="/img/illustrations/landing/icon-4.svg" alt="Tickets" />
      <h3>$30,000+</h3>
      <div className={styles.Title}>
        Donated to <br /> Charities
      </div>
    </div>
  </div>
);

export default Stats;
